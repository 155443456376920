<!-- <div class="innerbanner">
  <div class="container">
      <h1>Comfort Is Only a Few Steps Away</h1> 
</div>
</div> -->
<div class="jam_body">
<div style="background-color: #f1fafb !important;">
<div class="prduct">
<div class="container">
    <div class="row">
             <div class="col-sm-1 img_lf_hide">
          <ul class="prductleft">
              <li *ngFor="let img of gallery"><img  *ngIf="img"  src="/assets/images/{{img}}"  width="auto" height="auto" alt="product-img1" title="product-img1"/></li>
          </ul>
        </div>
      <div class="col-sm-7 prdtpdg">
       
          <img src="/assets/images/{{image}}" width="100%" height="500px" style="object-fit:contain;" alt="{{category_name}}" title="{{category_name}}"/>
         <!-- <div class="loading-gif" *ngIf="loading">
          <img width="100%" height="500px" style="object-fit:contain;" src="/assets/images/loading1.gif">
        </div>   -->
        <ul class="prduct_new">
          <li *ngFor="let img1 of bottom_gallery">
            <img *ngIf="img1" (click)="changeImage(img1)" src="/assets/images/{{img1}}"  width="auto" height="auto" alt="product-img1" title="product-img1"/>
          </li>
         
      </ul>
      </div>
      <div class="col-sm-4" style="font-family:oswald">
         
          <h3>{{category_name}}</h3>
          <h6 style="font-size:20px"><b>{{description}}</b></h6>
          <h6><span class="pric">${{price}}</span><span class="priclr"> <span style="color:#000">SALE</span> ${{sale_price}}</span></h6>
           <img src="/assets/images/yellow-stars.png" width="auto" height="auto" alt="review" title="review"/>   Reviews
          <ul class="prductli" >
              <li>{{point_1}}</li>
              <li>{{point_2}}</li>
              <li>{{point_3}}</li>
          </ul>
        
          <h4>Choose Size:</h4>
          <p>
            <select name="sizs" class="form-control" style="max-width: 150px;width:100%;border:2px solid #000;" [formControl]="size" id="sizs" formControlName="selected_size">
            <option  value="">Choose Size</option>
            <option *ngFor="let item of available_size" value="{{item}}">{{item}}</option>
          </select></p>
      

<h4>Choose Color:</h4>
    <div class="color-pallet">
          <label *ngFor="let item of available_colors" >
    <input class="input-color" value="{{item}}" type="radio" [formControl]="color" name="color">
    <span *ngIf="item!=123" [style.background-color]="getBackgroundColor(item)"> </span>
    <span *ngIf="item==123" style="background-image:url('/assets/images/color.png');background-size: contain;"></span>
    
    </label> 
  
    </div>
  
            <button class="prdtbtn" (click)="getStarted()" type="submit">Order Now</button>
            <div class="row" *ngIf="error">
              <div class="col-sm-12 text-center error-msg text-left">{{errorMessage}}</div>
            </div>
          </div>
         
    </div>

     
</div>
</div>
<!--<div class="mid midstep">
<div class="container">
    <div class="row">
        <h2>HOW JAMSOLES WORKS</h2>
      <div class="col-sm-4">
          <img src="assets/images/prductstep1.png" width="auto" height="auto" alt="jamsole-Customization Questions" title="jamsole-Customization Questions"/>
           <h3>Customization Questions</h3>
           <p>Answer a few quick questions to start down your path to truly custom orthotics.</p>

         </div>
      <div class="col-sm-4">
          <img src="assets/images/prductstep2.png" width="auto" height="auto" alt="Imprint Your Foot at Home with Our JAMsoles Kit" title="Imprint Your Foot at Home with Our JAMsoles Kit"/>
           <h3>Imprint Your Foot at Home with Our JAMsoles Kit</h3>
           <p>Take your literal first step toward comfort and create an imprint that allows for ultra-precise customization. Send back the imprints for free! </p>

           </div>
      <div class="col-sm-4">
          <img src="assets/images/prductstep3.png" width="auto" height="auto" alt="Receive Your JAMsoles and Get Moving" title="Receive Your JAMsoles and Get Moving"/>
          <h3>Receive Your JAMsoles and Get Moving</h3>
          <p>Honed according to your exact needs, your JAMsoles arrive ready to be used and to put you back on your feet.</p>
          </div>
    </div>
     <button class="btn1" (click)="getStarted()" routerLink="/get-started" routerLinkActive="active" type="submit">Get Started</button>
</div>
</div> -->

<div class="testimonial">
<div class="container">
  <div class="" style="max-width: 900px;width:100%;margin:auto;">
  <h2>SATISFACTION & COMFORT GUARANTEED</h2>
  <p style="font-size:20px">"Jamsoles gave me back my movement. Spending time outside playing with my kids is now comfortable and a joy once again."

  </p> 
  <p>- Danielle H. </p>
  <!-- <p class="achor"><a href="#">Read More</a></p> -->
  <p><img src="assets/images/star.jpg" width="auto" height="auto" alt="review" title="review"/> 11/07/20</p>
</div> 
</div>
</div>

<!-- <div class="footsection vrtical">
  <div class="col-sm-6 footleftpdg">
       <img src="assets/images/footstepleft.jpg" width="auto" height="auto" alt="ORTHOTICS FOR YOUR FOOT AND NO OTHER" title="ORTHOTICS FOR YOUR FOOT AND NO OTHER"/>
      </div>
   <div class="col-sm-5 footrgtpdg">
 <h2>ORTHOTICS FOR YOUR FOOT AND NO OTHER</h2> 
 <p>Our team of experts analyzes your foot's unique patterns to create orthotics built entirely for you and your goals. From foot shape to your lifestyle, we incorporate only you into your orthotics. The way it should be.</p>
 <p>The result is an insert for unparalleledcomfort and function.</p>
<ul>
<li>Improves foot function and comfort</li>
<li>Choose your design to fit your shoe and lifestyle</li>
<li>High-quality materials from heel to toe and from top to bottom</li>
<li>Built-in features to address your specific foot pains and concerns</li>
</ul>
<button class="btn" routerLink="/get-started" routerLinkActive="active">ORDER JAMSOLES</button>   </div>
   <div class="col-sm-1"></div>
</div> -->

<div class="footbanner">
<div class="container">
 <div class="row vrtical">
   <div class="col-sm-6 left">
     <h2>CRAFTED BY EXPERTS</h2> 
<p>Our team of skilled experts understands the unique anatomy of the foot, crafting orthotics according to years of podiatry training and experience.</p>
<button class="btn3" routerLink="/get-started" routerLinkActive="active">ORDER JAMSOLES</button>      </div>
   <div class="col-sm-6 rgtimg">
       <img loading="lazy" src="assets/images/foot-jam.jpg" width="auto" height="auto" alt="CRAFTED BY EXPERTS" title="CRAFTED BY EXPERTS">
      </div>
 </div>
</div>
</div>
</div>
</div>