import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css'],
})
export class InsuranceComponent implements OnInit {

 

  form: FormGroup;
  isSubmitted = false;
  orderData={};
  order = ["Flat Feet","Neuromas","Bunions","Hammertoes","Painful Feet/Ankles","Plantar Fasciitis","Heel Pain","Metatarsalgia (ball of foot pain)","Excessive Pronation (walking/gait abnormality)","Diabetes","Diabetic Neuropathy"];

  newArray : any = [];

  getCheckboxValues(ev: Event, data: any) {
    let obj = data;
    if ((ev.target as HTMLInputElement).checked) {
      this.newArray.push(obj);
    } else {
      let el = this.newArray.find((itm:any) => itm === data);
      if (el) {
        this.newArray.splice(this.newArray.indexOf(el), 1);
      }
    }
    console.log(this.newArray);
  }

  constructor(private fb: FormBuilder, private http: HttpClient,private router: Router,private route: Router) {
    const additional = {category : localStorage.getItem('category'), color : localStorage.getItem('color'), gender : localStorage.getItem('gender'), size : localStorage.getItem('size')}
    const order_data = JSON.parse(localStorage.getItem('order-data') || '{}');
    this.orderData = {...order_data, ...additional};
    this.form = fb.group({
      first_name: ['', Validators.required],
      middle_name: ['', Validators.required],
      last_name: ['', Validators.required],
      date_birth: ['', Validators.required],
      gender: ['', Validators.required],
      shipping_address: ['', Validators.required],
      phone_number: ['', Validators.required],
      email: ['', Validators.required],
      home_address: ['', Validators.required],
      insurance_company: ['', Validators.required],
      policy_number: ['', Validators.required],
      holder_name: ['', Validators.required],
      group_number: ['', Validators.required],
      insurance_number: ['', Validators.required],
      holder_fname: ['', Validators.required],
      holder_lname: ['', Validators.required],
      holder_dob: ['', Validators.required],
      age: ['', Validators.required],
      height: ['', Validators.required],
      weight: ['', Validators.required],
      sex: ['', Validators.required],
      shoe_size: ['', Validators.required],
      shoe_brand: ['', Validators.required],
      purpose: ['', Validators.required],
      parent_name: ['', Validators.required],
      date_time: ['', Validators.required],
      patient_name: ['', Validators.required],
      date: ['', Validators.required],
      suffer:['',Validators.required],
      conditions   : [[this.newArray], Validators.required],
      patient_sign: ['test',Validators.required],
      parent_sign: ['test123',Validators.required],
    });
  }


  submitForm() {
    this.isSubmitted = true; 
    if (this.form.valid) {
      const formData = {order_data : this.orderData, insurance : this.form.value};
      console.log(formData)
      this.http.post('https://jamsoles.com/api/create-insurance', formData).subscribe((response) => {
        if(response) {
          localStorage.clear();
          this.route.navigate(['/thankyou']);
        }
      });
    }
  }

  ngOnInit(): void {
  }

}
