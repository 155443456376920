<div class="jam_body">
  <div class="innerbanner">
    <div class="container">
      <h1>Contact Story</h1>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-12 text-center">
      <p>&nbsp;</p> <p>&nbsp;</p> <p>&nbsp;</p> <p>&nbsp;</p>
    <h2>Coming Soon...</h2> <p>&nbsp;</p> <p>&nbsp;</p>
    </div>
</div>
</div>
</div>