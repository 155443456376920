import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from '../services.service';


@Component({
  selector: 'app-process-user',
  templateUrl: './process-user.component.html',
  styleUrls: ['./process-user.component.css']
})
export class ProcessUserComponent implements OnInit {
  order_id : any;
  response: null;
  old_order:[];
  loading: boolean;

  gender : any;

  id: any;
  category : any;
  size : any;
  color : any;
  price: any;
  sale_price: any;
  quantity: any;
  email: any;
  first_name: any;
  last_name: any;
  phone_number: any;
  address: any;
  street: any;
  city: any;
  country: any;
  state: any;
  zip: any;
  sh_first_name: any;
  sh_last_name: any;
  sh_street: any;
  sh_address: any;
  sh_city: any;
  sh_country: any;
  sh_state: any;
  sh_zip: any;

  constructor(private service: ServicesService, private route: ActivatedRoute, private router: Router) {}
  ngOnInit(): void {
    this.order_id = this.route.snapshot.paramMap.get('order_id');
    console.log(this.order_id);
    
    this.service.getPreviousOrder(this.order_id)
    .subscribe(
      (response) => {                           //next() callback
        console.log('response received');
        console.log(response.sh_first_name);
        this.gender = 'male';
        this.id = response.pid;
        this.color = response.color;
        this.size = response.size;
        this.category = response.category_name;
        this.price = response.price;
        this.sale_price = response.sale_price;
        this.quantity = 1;
        this.email = response.email;
        this.first_name = response.first_name;
        this.last_name = response.last_name;
        this.phone_number = response.phone_number;
        this.address = response.address;
        this.street = response.street;
        this.city = response.city;
        this.country = response.country;
        this.state = response.state;
        this.zip = response.zip;
        this.sh_first_name = response.sh_first_name;
        this.sh_last_name = response.sh_last_name;
        this.sh_street = response.sh_street;
        this.sh_address = response.sh_address;
        this.sh_city = response.sh_city;
        this.sh_country = response.sh_country;
        this.sh_state = response.sh_state;
        this.sh_zip = response.sh_zip;
       
   
      
      },
      (error) => {   
       //error() callback
       
        console.error('Request failed with error')
       
      },
      () => {       
                              //complete() callback
        console.error('Request completed')      //This is actually not needed 
        localStorage.setItem('gender',this.gender);
        localStorage.setItem('category',this.category);
        localStorage.setItem('order-data', JSON.stringify({ id : this.id,
          color : this.color,
          size : this.size,
          category : this.category,
          price : this.price,
          sale_price : this.sale_price,
          quantity : 1,
          email : this.email,
          first_name : this.first_name,
          last_name : this.last_name,
          phone_number : this.phone_number,
          address : this.address,
          street : this.street,
          city : this.city,
          country : this.country,
          state : this.state,
          zip : this.zip,
          sh_first_name : this.sh_first_name,
          sh_last_name : this.sh_last_name,
          sh_street : this.sh_street,
          sh_address : this.sh_address,
          sh_city : this.sh_city,
          sh_country : this.sh_country,
          sh_state : this.sh_state,
          sh_zip : this.sh_zip,
          reference_order_id:this.order_id}));
          this.router.navigate(['/get-started']);

      });
      
      if(this.response==null){
        console.log("test");
        //this.route.navigate(['/home']);
     }
  }

}
