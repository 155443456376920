<div class="container">
<div class="form">
    <div class="insurance">
          <h3>Patient Information</h3>
          <form action="" id="insurance"  enctype="multipart/form-data" [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="" style="padding:15px;">
              <div class="row">
            <div class="col-md-4">
                  <label for="first-name">First Name:
                <input class="form-control" type="text" id="first-name" formControlName="first_name" name="first_name" >
                <div *ngIf="isSubmitted && form.get('first_name')?.hasError('required')" class="text-danger">First Name is required</div>
              </label>
                </div>
                <div class="col-md-4">
                  <label for="middle-name">Middle Name:
                <input class="form-control" type="text" id="middle-name" formControlName="middle_name" name="middle_name">
                <div *ngIf="isSubmitted && form.get('middle_name')?.hasError('required')" class="text-danger">Middle Name is required</div>
              </label>
                </div>
                <div class="col-md-4">
                  <label for="last-name">Last Name:
                <input class="form-control" type="text" id="last-name" formControlName="last_name" name="last_name">
                <div *ngIf="isSubmitted && form.get('last_name')?.hasError('required')" class="text-danger">Last Name is required</div>
              </label>
                </div>
           <!-- <div class="col-md-4">
                  <label for="ssn">Social Security #:
                <input class="form-control" type="text" id="ssn" name="social_security">
              </label>
                </div> -->
            <div class="col-md-4">
                  <label for="date-of-birth">Date of Birth:
                <input class="form-control" type="date" id="date-of-birth" formControlName="date_birth" name="date_birth">
                <div *ngIf="isSubmitted && form.get('date_birth')?.hasError('required')" class="text-danger">Date of Birth is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="gender">Gender:
                
              
              <select class="form-control" name="gender"  formControlName="gender">
              <option value="">Select Gender</option>
              <option value="Male"> Male</option> 
              <option value="Female"> Female</option>
            </select>
            <div *ngIf="isSubmitted && form.get('gender')?.hasError('required')" class="text-danger">Gender is required</div>
        </label>
                </div>
            <div class="col-md-4">
                  <label for="shipping-address">Shipping Address:
                <input class="form-control" type="text" id="shipping-address" formControlName="shipping_address" name="shipping_address">
                <div *ngIf="isSubmitted && form.get('shipping_address')?.hasError('required')" class="text-danger">Shipping Address is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="phone-number">Phone Number:
                <input class="form-control" type="text" id="phone-number" formControlName="phone_number" name="phone_number">
                <div *ngIf="isSubmitted && form.get('phone_number')?.hasError('required')" class="text-danger">Phone Number is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="email">Email Address:
                <input class="form-control" type="email" id="email" formControlName="email" name="email">
                <div *ngIf="isSubmitted && form.get('email')?.hasError('required')" class="text-danger">Email Address is required</div>
              </label>
                </div>
                <div class="col-md-4">
                  <label for="home-address">Home Address:
                <input class="form-control" type="address" id="home-address" formControlName="home_address" name="home_address">
                <div *ngIf="isSubmitted && form.get('home_address')?.hasError('required')" class="text-danger">Home Address is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="insurance-company">Insurance Company Name:
                <input class="form-control" type="text" id="insurance-company" formControlName="insurance_company" name="insurance_company">
                <div *ngIf="isSubmitted && form.get('insurance_company')?.hasError('required')" class="text-danger">Insurance Company Name is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="policy-number">Policy Number:
                <input class="form-control" type="text" id="policy-number" formControlName="policy_number" name="policy_number">
                <div *ngIf="isSubmitted && form.get('policy_number')?.hasError('required')" class="text-danger">Policy Number is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="policy-holder-name">Policy Holder/Insurance Subscriber Name:
                <input class="form-control" type="text" id="policy-holder-name" formControlName="holder_name" name="holder_name">
                <div *ngIf="isSubmitted && form.get('holder_name')?.hasError('required')" class="text-danger">Policy Holder/Insurance Subscriber Name is required</div>
              </label>
                </div>
                <div class="col-md-4">
                  <label for="group-number">Group Number:
                <input class="form-control" type="text" id="group-number" formControlName="group_number" name="group_number">
                <div *ngIf="isSubmitted && form.get('group_number')?.hasError('required')" class="text-danger">Group Number is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="insurance-phone-number">Insurance Company Phone Number for Doctor's Office:
                <input class="form-control" type="text" id="insurance-phone-number" formControlName="insurance_number" name="insurance_number">
                <div *ngIf="isSubmitted && form.get('insurance_number')?.hasError('required')" class="text-danger">Insurance Company Phone Number is required</div>
              </label>
                </div>
          </div>
            </div>
        <h3 >Policy Holder Info if different from Patient Information</h3>
        <div class="" style="padding:15px;">
              <div class="row">
            <div class="col-md-4">
                  <label for="policy-holder-first-name">Policy Holder First Name:
                <input class="form-control" type="text" id="policy-holder-first-name" formControlName="holder_fname" name="holder_fname">
                <div *ngIf="isSubmitted && form.get('holder_fname')?.hasError('required')" class="text-danger">Policy Holder First Name is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="policy-holder-last-name">Policy Holder Last Name:
                <input class="form-control" type="text" id="policy-holder-last-name" formControlName="holder_lname" name="holder_lname">
                <div *ngIf="isSubmitted && form.get('holder_lname')?.hasError('required')" class="text-danger">Policy Holder Last Name is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="policy-holder-dob">Policy Holder DOB:
                <input class="form-control" type="date" id="policy-holder-dob" formControlName="holder_dob" name="holder_dob">
                <div *ngIf="isSubmitted && form.get('holder_dob')?.hasError('required')" class="text-danger">Policy Holder DOB is required</div>
              </label>
                </div>
            
          </div>
            </div>
        <h3>Check all that apply about the patient</h3>
        <div class="" style="padding:15px;">
              <div class="row">
            <div class="col-md-4">
                  <label for="i_suffers">I Suffer From:
                <input class="form-control" type="text" id="i_suffer" formControlName="suffer" name="suffer">
                <div *ngIf="isSubmitted && form.get('suffer')?.hasError('required')" class="text-danger">Suffer from is required</div>
              </label>
                </div>
          </div>
          <div class="row">
            <div class="col-md-4" *ngFor="let item of order; let i = index">
            <label for="flat-feet">
            <input  [id]="'checkbox-' + i" type="checkbox" [name]="item"  (change)="getCheckboxValues($event, item)"> {{item}}
            </label>
            </div>
            </div>
                     
            </div>
        <h3>Additional Information:</h3>
        <div class="" style="padding:15px;">
              <div class="row">
            <div class="col-md-4">
                  <label for="age">Age:
                <input class="form-control" type="text" id="age" formControlName="age" name="age">
                <div *ngIf="isSubmitted && form.get('age')?.hasError('required')" class="text-danger">Age is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="height">Height:
                <input class="form-control" type="text" id="height" formControlName="height" name="height">
                <div *ngIf="isSubmitted && form.get('height')?.hasError('required')" class="text-danger">Height is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="weight">weight:
                <input class="form-control" type="text" id="weight" formControlName="weight" name="weight">
                <div *ngIf="isSubmitted && form.get('weight')?.hasError('required')" class="text-danger">Weight is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="sex">Sex:
                <input class="form-control" type="text" id="sex" formControlName="sex" name="sex">
                <div *ngIf="isSubmitted && form.get('sex')?.hasError('required')" class="text-danger">Gender is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="shoe-size">Shoe Size:
                <input class="form-control" type="text" id="shoe-size" formControlName="shoe_size" name="shoe_size">
                <div *ngIf="isSubmitted && form.get('shoe_size')?.hasError('required')" class="text-danger">Shoe Size is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="athletic-shoe-brand">Athletic Shoe Brand most often used:
                <input class="form-control" type="text" id="athletic-shoe-brand" formControlName="shoe_brand" name="shoe_brand">
                <div *ngIf="isSubmitted && form.get('shoe_brand')?.hasError('required')" class="text-danger">Athletic Shoe Brand is required</div>
              </label>
                </div>
            <div class="col-md-4">
                  <label for="orthotics-purpose">Im ordering orthotics for what type of shoes/activity:
                <input class="form-control" type="text" id="orthotics-purpose" formControlName="purpose" name="purpose" >
                <div *ngIf="isSubmitted && form.get('purpose')?.hasError('required')" class="text-danger">Type of shoes/activity is required</div>
              </label>
                </div>
          </div>
            </div>
        <div class="" style="padding:15px;">
              <label for="orthotics-purpose">Terms & Conditions:</label>
              <div class="crol">
            <strong>Purpose:</strong>
            <p>I, the undersigned patient, hereby consent to the registration process and authorize the healthcare provider to bill my insurance for the custom orthotics services provided through telemedicine.</p>
            <strong>Consent to Telemedicine:</strong>
            <p>I understand that telemedicine involves the use of electronic communications to enable healthcare providers at a distant location to perform a preliminary evaluation in order to create custom orthotic insoles using a propriatary method whereby a foam box is shipped to me so that I may step into and create a three dimension model of my foot so that the lab could make a custom orthotic. I consent to receiving orthotic services through telemedicine, and I acknowledge that for most optimal evaluation I need to see my Podiatrist locally for a full physical exam should I desire a higher level of care.</p>
            <strong>Billing Authorization:</strong>
            <p>I authorize the healthcare provider to release and disclose my health information, including but not limited to diagnoses, treatment plans, and related information, to the insurance company for the purpose of billing and reimbursement.</p>
            <strong>HIPAA Privacy Acknowledgment:</strong>
            <p>I understand that my health information will be protected by the Health Insurance Portability and Accountability Act (HIPAA) regulations. I acknowledge that I have received and reviewed the Notice of Privacy Practices provided by the healthcare provider as displayed on the JAMsoles website.</p>
            <strong>Telemedicine Custom Orthotics Service Disclaimer</strong>
            <strong>Disclaimer:</strong>
            <p>Thank you for choosing our telemedicine custom orthotics service. Before proceeding, please carefully read and acknowledge the following:</p>
            <strong>1. Limited Physical Examination</strong>
            <p>Telemedicine consultations for custom orthotics involve a limited physical examination based on the information provided by you, including images of your feet using a foam box or similar equipment. While we will make every effort to ensure accurate evaluation, this method may have limitations compared to in-person assessments.</p>
            <strong>2.Orthotic Recommendations:</strong>
            <p>The orthotic recommendations provided through telemedicine are based on the information available during our virtual consultation thru your accurate filling of online question answers.  These recommendations are subject to change if additional in-person assessment is deemed necessary.</p>
            <strong>3. Foam Box Usage:</strong>
            <p>To create custom orthotics, you will be provided with instructions on using a foam box to capture foot impressions. It is crucial to follow these instructions carefully for accurate results. Clear video instructions are on our home page on JAMsoles.com. The foam box and any associated materials are to be used solely for the purpose of this consultation</p>
            <strong>4. Privacy and Security:</strong>
            <p>We prioritize the security and privacy of your health information. All interactions and information shared during the telemedicine consultation via use of our JAMsoles ordering portal will be handled in accordance with applicable privacy laws, including HIPAA. However, please ensure you are in a private and secure location during the online filling of this form and online information requests on JAMsoles.com to protect your personal health information.</p>
            <strong>5. Informed Consent:</strong>
            <p>By participating in this telemedicine custom orthotics service, you acknowledge that you have been informed of the potential limitations of virtual assessments, and you consent to proceed with the process using the foam box.</p>
            <strong>6. Follow-up and Modifications:</strong>
            <p>After receiving your custom orthotics, it is essential to follow any provided instructions for wear /break in instructions and to promptly report any issues or discomfort to our office or to your local podiatrist for the rare possibility of adjustments.  Please note follow-up consultations may be necessary for adjustments or modifications.</p>
            <strong>7. Emergency Situations:</strong>
            <p>In case of a medical emergency or worsening of your condition, please seek immediate medical attention through your local healthcare provider or call emergency services.</p>
            <strong>8. Contact Information:</strong>
            <p>For questions, concerns, or technical difficulties during the telemedicine consultation, please contact our office at [Contact 3102479255].</p>
            <p>By participating in this telemedicine custom orthotics service, you acknowledge and accept the terms and conditions outlined in this disclaimer.</p>
          </div><label for="1"> <input  type="checkbox" id="term" name="term" value="1"> I Agree to the Terms & Conditions
                  
                </label>
              
            </div>
       
        <div class="" style="padding:15px;">
              <div class="row">
            <div class="col-md-4 " >
                   <label for="full-name">Parent/Guardian Signature (if applicable):
                <input class="form-control" type="text" formControlName="parent_name"  name="parent_name" >
                <div *ngIf="isSubmitted && form.get('parent_name')?.hasError('required')" class="text-danger">Parent/Guardian Signature is required</div>
              </label>
                  <label for="date">Date:
                <input class="form-control" type="date" id="date" formControlName="date_time" name="date_time" >
                <div *ngIf="isSubmitted && form.get('date_time')?.hasError('required')" class="text-danger">Date Time is required</div>
              </label>
                </div>
            <div class="col-md-4"> </div>
            <div class="col-md-4">
                  <label for="full-name">Patient Name:
                <input class="form-control" type="text" id="patient-name" formControlName="patient_name" name="patient_name">
                <div *ngIf="isSubmitted && form.get('patient_name')?.hasError('required')" class="text-danger">Parent Name is required</div>
              </label>
                  <label for="date">Date:
                <input class="form-control" type="date" id="date" formControlName="date" name="date">
                <div *ngIf="isSubmitted && form.get('date')?.hasError('required')" class="text-danger">Date is required</div>
              </label>
                  <label for="full-name">Patient Signature:</label>
                  <div id="signature"style="border: 2px dotted #000;"></div>
                  <div *ngIf="isSubmitted && form.get('signature')?.hasError('required')" class="text-danger">Signature is required</div>

                </div>
                <input  type="hidden"  name="patient_sign" id="patient_sign"  value="" required>
            <input  type="hidden"  name="parent_sign" id="parent_sign" value="" required>
            <div class="col-md-12 text-center">
                  <input class="submit-button" type="submit" value="Submit" name="submit">
                </div>
          </div>
            </div>
      </form>
        </div>
  </div>
</div>



