import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TK_CONFIG, Config, AcceptJSService, CreditCard } from '@openutility/acceptjs-angular-wrapper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'jamsoles';
  location: any;
  
  constructor(private _acceptJSSrv: AcceptJSService, _router: Router, location: Location) {
    this.location = location.path(); 
    console.log(location.path());
   }

  async onSubmit(cc: CreditCard): Promise<void> {
    try {
      const nonce = await this._acceptJSSrv.generatePaymentNonce(cc);
      // submit nonce to your server with payment amount
    } catch (ex) {
      console.error(ex);
    }
  }
}