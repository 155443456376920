
<div class="jam_body">
<!-- <div class="innerbanner">
  <div class="container">
    <h1>Comfort Is Only a Few Steps Away</h1>
  </div>
</div> -->
<div class="leftrgt">
  <div class="container">
    <!--<div class="col-sm-6">
      <p>STEP{{step}}</p>
    </div>-->
    <div class="col-sm-12 rgt"><a class="get-started" routerLink="/" routerLinkActive="active" >Back to home</a></div>
  </div>
</div>
<div class="midsteps">
  <div class="container">
    <mat-horizontal-stepper #stepper>
      <form action="" class="" method="">
        <mat-step label="Step 1" >
          <h2>Please select your gender so we can better understand your natural foot shape.</h2>
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-3 col-xs-6 step">
              <label for="rdio_male"><img src="/assets/images/person1.png" alt="Graphical Image showing a male person" title="Select your gender"/>
              <p>Male</p>
              <input type="radio" [(ngModel)]="gender_chk" id="rdio_male" name="gender" value="male" [formControl]="gender" ></label>
            </div>
           
            <div class="col-sm-3 col-xs-6 step">
              <label for="rdio_female"><img src="/assets/images/person.png" alt="Graphical Image showing a female person" title="Select your gender"/>
              <p>Female</p>
              <input type="radio"  [(ngModel)]="gender_chk" id="rdio_female" name="gender" value="female"  [formControl]="gender" ></label>
            </div>
           
            <div class="col-sm-3"></div>
          </div>

          <div class="row" *ngIf="error">
            <div class="col-sm-12 text-center error-msg">Please Select Gender!</div>
          </div>
         
          <div class="row">
            <div class="col-sm-12 text-center">
              <button mat-button (click)="goNext(stepper)" class="btn1" type="submit" >Next</button>
            </div>
          </div>
        </mat-step>
      </form>
      <form action="" class="" method="" >
        <mat-step label="Step 2">
          <h2>Please select your shoe type to receive orthotics that best match your daily comfort goals.</h2>
          <div class="row">
            
            <div class="col-sm-3 step">
              <label  for="rdio_1"><img src="assets/images/dress1.png" alt="Dress Shoes/Boots" title="Dress Shoes/Boots">
              <p>Dress Shoes/Boots</p>
              <input  type="radio" id="rdio_1" name="category" value="dress-shoes-boots" [formControl]="category"></label>
            </div>
            <div class="col-sm-3 step">
              <label for="rdio_2"><img src="assets/images/ateltic1.png" alt="Athletic/Comfort Shoes" title="Athletic/Comfort Shoes">
              <p>Athletic/Comfort Shoes</p>
              <input type="radio"  [(ngModel)]="cat_chk" id="rdio_2" name="category" value="athletic-comfort-shoes" [formControl]="category"></label>
            </div>
            <div class="col-sm-3 step">
              <label for="rdio_3"><img src="assets/images/diabetic1.png" alt="Diabetic Patient" title="Diabetic Patient">
              <p>Diabetic Patient</p>
              <input type="radio" id="rdio_3" [(ngModel)]="cat_chk" name="category" value="diabetic-patient" [formControl]="category"></label>
            </div>
            <div class="col-sm-3 step">
              <label for="rdio_4"><img src="assets/images/pedartic1.png" alt="Pediatric Patient" title="Pediatric Patient">
              <p>Pediatric Patient</p>
              <input type="radio" id="rdio_4" [(ngModel)]="cat_chk" name="category" value="pediatric-patient" [formControl]="category"></label>
            </div>
            </div>
            <div class="row" *ngIf="error">
              <div class="col-sm-12 text-center error-msg">Please Select Category!</div>
            </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <button mat-button (click)="goBack(stepper)" class="btn1 pull-left" type="submit" matStepperPrevious>Back</button>
           
              <button mat-button (click)="goNext1(stepper)" class="btn1 get_next" type="submit" >Next</button></div>
          </div>
        </mat-step>
      </form>
    </mat-horizontal-stepper>
  </div>
</div>
</div>