import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule } from '@angular/material/stepper';
import { StoryComponent } from './story/story.component';
import { WhyJamsoleComponent } from './why-jamsole/why-jamsole.component';
import { FeaturesComponent } from './features/features.component';
import { ContactComponent } from './contact/contact.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentComponent } from './payment/payment.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { HttpClientModule } from '@angular/common/http';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ReturnsRefundsComponent } from './returns-refunds/returns-refunds.component';
import { SubmissionComponent } from './submission/submission.component';
import { TK_CONFIG, Config, AcceptJSService } from '@openutility/acceptjs-angular-wrapper';
import { ExplainerVideoComponent } from './explainer-video/explainer-video.component';
import { FoamBoxComponent } from './foam-box/foam-box.component';
import { PlaceJamsolesComponent } from './place-jamsoles/place-jamsoles.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProcessUserComponent } from './process-user/process-user.component';
import { InsuranceComponent } from './insurance/insurance.component';

const AcceptJSConfig: Config = {
  acceptjsUrl: 'https://js.authorize.net/v1/Accept.js'
  , apiLoginID: '5FDx8svHt7w'
  , clientKey: '8Wprdwm7mJKBRv6bbr4rF4VjSekLvhc95jYfBVv3nM45AMe8X4NXZNfn4qZ68kLk'
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ProductViewComponent,
    GetStartedComponent,
    StoryComponent,
    WhyJamsoleComponent,
    FeaturesComponent,
    ContactComponent,
    CheckoutComponent,
    PaymentComponent,
    ThankyouComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    TermsConditionsComponent,
    ReturnsRefundsComponent,
    SubmissionComponent,
    ExplainerVideoComponent,
    FoamBoxComponent,
    PlaceJamsolesComponent,
    ProcessUserComponent,
    InsuranceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatStepperModule,
    HttpClientModule,
    MatTooltipModule,
    
  ],
  bootstrap: [ AppComponent ],
  
  providers: [
    {
      provide: TK_CONFIG,
      useValue: AcceptJSConfig
    },
    AcceptJSService
  ]
})
export class AppModule { }
