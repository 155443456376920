<div class="jam_body">
  <div class="innerbanner">
    <div class="container">
      <h1>Returns and Refunds</h1>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12 returns-refunds">
          <p>We strive to ensure that every orthotics order fully meets your standards and delivers full satisfaction. However, we understand the importance of being able to decide in a stress-free way. That is why we have a 120-day money-back guarantee if your orthotics do not fit just the way you hoped. Contact our customer service email at <a href="mailto:customercare@jamsoles.com">customercare@jamsoles.com</a>. 
        </p>
          <p>We will work with you to find the best solution for you if you are dissatisfied with your orthotics. In addition to the option for a refund, we also offer free adjustments for the first 120 days.</p>
          </div>
          </div>
          </div>
          </div>
          