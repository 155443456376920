import { Injectable } from '@angular/core';
 
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  baseURL: string = "https://jamsoles.com/api/";
  constructor(private http: HttpClient) { }

  getProduct(slug: any): Observable<any> {
    return this.http.get(this.baseURL + 'product/' + slug );
  }
  getProductByCatSlug(slug: any): Observable<any> {
    return this.http.get(this.baseURL + 'product-cat-slug/' + slug );
  }

  getPreviousOrder(order_id: any): Observable<any> {
    return this.http.get(this.baseURL + 'previous_order/' + order_id );
  }

  getFaq(){

    return this.http.get(this.baseURL + 'faq');
  }
  createorder(data:any){

    return this.http.post(this.baseURL + 'payment',data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateOrderStatus(order_id:any){

    return this.http.post(this.baseURL + 'order-update',{order_id:order_id}).pipe(
      map((response) => {
        return response;
      })
    );
  }

  previousOrderChecking(data:any){
    console.log(data)
        return this.http.post(this.baseURL + 'check-previous-order',data).pipe(
          map((response) => {
            return response;
          })
        );
      }

  contactform(data:any){
    console.log(data)
        return this.http.post(this.baseURL + 'contact',data).pipe(
          map((response) => {
            return response;
          })
        );
      }
}


