import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-place-jamsoles',
  templateUrl: './place-jamsoles.component.html',
  styleUrls: ['./place-jamsoles.component.css']
})
export class PlaceJamsolesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
