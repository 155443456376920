<div class="jam_body">
<div class="container payment-page">
    <div class="row">
      <div class="col-md-6">
        <h2 class="title-text">Shipping Address</h2>
        <div class="clearfix"></div>
        <div class="border-round-alone">
            <div [innerHTML]="address"></div>
        </div>
        <h2 class="title-text">Billing Address</h2>
        <div class="clearfix"></div>
        <div class="border-round-alone">
            <div [innerHTML]="sh_address"></div>
        </div>

      </div>
      <div class="col-md-6">
        <h2 class="title-text">Payment Information</h2>
        <div class="form-start">
          <div class="order-summary">
            <p><span>Subtotal</span> <span class="pull-right">${{order_data.quantity*order_data.price}}</span></p>
            <p><span>Shipping</span> <span class="pull-right">FREE</span></p>
            <p><span>Discounts</span> <span class="pull-right">- ${{order_data.quantity*(order_data.price-order_data.sale_price)}}</span></p>
            
            <hr>
        
            <p><strong><span>Total</span> <span class="pull-right"> ${{order_data.quantity*order_data.sale_price}}</span></strong></p>
           

            <div class="j_container" *ngIf="payment_type=='insurance'"  style="margin-top: 30px;font-family: 'Oswald', sans-serif;">
              <div class="j_item">
                <p style="font-size:20px;"><strong><span>Pay with Insurance or HSA plan</span></strong></p>
              </div>
              <div class="j_item">
                <a routerLink="/insurance" routerLinkActive="active"  class="ins_btn">Click here to fill insurance Details</a>
              </div>

            </div>


            <form *ngIf="payment_type=='self_pay'" action="" class=""  [formGroup]="paymentform" method="" name="" #form="ngForm" (ngSubmit)="onSubmit()">
              
        <!--  <input type="radio" id="cod" name="cod" value="Cash On Delivery" [ngClass]="{ 'is-invalid': payment_status && f.payment_status.errors }" formControlName="payment_status">
            {{payment_status.value}}
             <label class="cod_btn"for="cod">Cash On Delivery</label>
             <div *ngIf="submitted && f.payment_status.errors" class="invalid-feedback">
              <div *ngIf="f.payment_status.errors.required">Please choose Payment method.</div>
          </div>-->
               <h3 class="payment_head">Enter Card Details</h3>
                <div class="col-md-12">
                <div class="form-group">
                  <input type="text" (keypress)="numberOnly($event)" maxlength="16"  name="card_number" class="form-control" id="card_number" placeholder="Enter Card Number" formControlName="card_number" [ngClass]="{ 'is-invalid': submitted && f.card_number.errors }"/>
                  <div *ngIf="submitted && f.card_number.errors" class="invalid-feedback">
                    <div *ngIf="f.card_number.errors.required">Card Number is required</div></div>
                </div>
                </div>
                <div class="col-md-6">
                <div class="form-group">
                  <select type="text" (keypress)="numberOnly($event)" name="exp_month" required maxlength="2" id="exp_month" class="form-control" autocomplete="off" placeholder="Expiry Month" formControlName="exp_month" [ngClass]="{ 'is-invalid': submitted && f.exp_month.errors }">
                    <option value="" selected>Expiration Month</option>
                      <option value="01">Jan</option>
                      <option value="02">Feb</option>
                      <option value="03">Mar</option>
                      <option value="04">Apr</option>
                      <option value="05">May</option>
                      <option value="06">Jun</option>
                      <option value="07">Jul</option>
                      <option value="08">Aug</option>
                      <option value="09">Sep</option>
                      <option value="10">Oct</option>
                      <option value="11">Nov</option>
                      <option value="12">Dec</option>
                  </select>
                  <div *ngIf="submitted && f.exp_month.errors" class="invalid-feedback">
                    <div *ngIf="f.exp_month.errors.required">Expiry Month is required</div></div>
                </div>
                </div>
                <!--<div class="col-md-6">
                <div class="form-group">
                  <input type="text" (keypress)="numberOnly($event)" name="exp_year" required maxlength="4" id="exp_year" class="form-control" autocomplete="off" placeholder="Expiry Year" formControlName="exp_year"/>
                  <div *ngIf="submitted && f.exp_year.errors" class="invalid-feedback">
                    <div *ngIf="f.exp_year.errors.required">Expiry Year is required</div></div>
                </div>
                </div>-->
                <div class="col-md-6">
                  <div class="form-group">
                    <select type="text" (keypress)="numberOnly($event)" name="exp_year" required maxlength="4" id="exp_year" class="form-control" autocomplete="off" placeholder="Expiry Year" formControlName="exp_year">
                      <option value="" selected>Expiration Year</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2031">2031</option>
                      <option value="2032">2032</option>
                    </select>
                    <div *ngIf="submitted && f.exp_year.errors" class="invalid-feedback">
                      <div *ngIf="f.exp_year.errors.required">Expiry Year is required</div></div>
                  </div>
                  </div>
                <div class="col-md-12">
                <div class="form-group">
                  <input type="text" (keypress)="numberOnly($event)" maxlength="4" name="card_code" required class="form-control" id="card_code" autocomplete="off" placeholder="CVV/ Security Code" formControlName="card_code"/>
                 <a href="#" title="Amex on front of card.If visa Mastercard discovery on backside." class="tooltip"> </a>
          
                  <div *ngIf="submitted && f.card_code.errors" class="invalid-feedback">
                    <div *ngIf="f.card_code.errors.required">Card Code is required</div></div>
                </div>
              </div>
              <button class="btn btn_light_blue" [disabled]="!form.valid" type="submit" >{{btn_text}}</button>
              <div id="payment_error" style="color:red;text-align:center;margin-bottom:15px;">{{payment_error}}</div>
              </form>
                </div>
              </div>
            </div>
          </div>
          </div>
  
  </div>
