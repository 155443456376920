<div class="jam_body">
  <div class="innerbanner">
    <div class="container">
      <h1>Terms & Conditions</h1>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12 terms-conditions">
       

        <h2>General</h2>
        <p>Access to and use of this Website and the products and services available through this Website (collectively, the "Services") are subject to the following terms, conditions, and notices (the "Terms and Conditions"). By using the Services, you are agreeing to all of the Terms and Conditions, as may be updated by us regularly.</p>
        
        <p>You should check this page regularly to take notice of any changes we may have made to the Terms and Conditions. Access to this Website is permitted on a temporary basis, and JAMsoles reserves the right to remove or amend the Services without notice. We will not be liable if, for any reason, this Website is unavailable at any time or for any period.</p>
        
        <p>Periodically we may restrict access to some parts or the entire Website. Your use of the Website is contingent upon your acceptance of our Privacy Policy and the following Terms and Conditions. If you do not agree to the Terms and Conditions of business contained in the following pages, then you may not use the JAMsoles website.</p>
        
        <p>If you have any inquiries, comments, or concerns regarding this agreement or any other part of this site, or regarding any of our featured products and services, or if you have experienced technical problems while using this site, please send an e-mail to <a  href="mailto:customercare@jamsoles.com">customercare@jamsoles.com</a></p>
        
        <h2>Privacy Policy</h2>
        <p>Our privacy policy, which sets out how we will use your information, can be found under Privacy Policy. By using this Website, you consent to the processing described therein and warrant that all data provided by you is accurate.</p>
        
        <h2>Cookies</h2>
        <p>Like many websites, we also use "cookie" technology. A cookie is a small data file that we transfer to your computer's hard disk. We do not use cookies to collect personally identifiable information. We use cookies to better understand how you interact with the site and our service, to monitor aggregate usage by our users and web traffic routing on the site, and to improve the site and our services.</p>
        
        <h2>Your Obligations and  Conduct</h2>
        <p>You must provide us with true and accurate information, for example: your real name, phone number, e-mail address, and other requested information as indicated. Falsifying or omitting contact information such as a name, address, and/or telephone number when registering with JAMsoles is not permitted. You are also not permitted to use fax or disconnected numbers as a telephone number. When using this Website, you must obey all applicable international, federal, state, and local laws.</p>
        <p>It is your responsibility to select a password that is unique to your JAMsoles account and that you do not disclose this information to anyone else or keep this information in an insecure place.</p>
        
        <p>You must not misuse this Website. You will not: commit or encourage a criminal offense; transmit or distribute a virus, trojan, worm, logic bomb or post any other material which is malicious, technologically harmful, in breach of confidence, or in any way offensive or obscene; hack into any aspect of the Services; corrupt data; cause annoyance to other users; infringe upon the rights of any other person's proprietary rights; send any unsolicited advertising or promotional material, commonly referred to as "spam"; or attempt to affect the performance or functionality of any computer facilities of or accessed through this Website. Breaching this provision would constitute a criminal offense under the Computer Misuse Act 1990. JAMsoles will report any such breach to the relevant law enforcement authorities and disclose your identity to them.</p>
        
        <p>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful material that may infect your computer equipment, computer programs, data, or other proprietary material due to your use of this Website or to your downloading of any material posted on it, or on any website linked to it.</p>
        
        <p>We reserve the right to bar users from this Site on a permanent or temporary basis. Any such user shall be notified and must not then attempt to use this Website under any other name or through any other user. JAMsoles will report any breach of these rules that would constitute a criminal offense under the Computer Misuse Act of 1990 to the relevant law enforcement agencies and disclose your identity to them.</p>
        
        <h2>Intellectual Property, Software, And Content</h2>
        <p>This Website is owned by and operated by JAMsoles. Unless otherwise noted in this Website, JAMsoles owns the copyright with respect to all content on the Website. Content includes: text, graphics, logos, audio clips, trademarks, software server information, and anything else hosted on this Website. The JAMsoles logo, name, tagline, and other marks indicated on the Website are the trademarks or registered trademarks of JAMsoles in the US, Canada, Japan, China, Australia, and Europe. All rights to content, services, and server information are reserved. Any modification made to the content of this Website by a third party is a violation of JAMsoles copyright. Additionally, the JAMsoles website may contain other proprietary notices and copyright information, the terms of which must be observed and followed.</p>
        
        <p>The website graphics, logos, page headers, button icons, scripts, and service names are the trademarks or trade dress of JAMsoles. JAMsoles trademarks and trade dress may not be used in connection with any product or service that is not JAMsoles, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits JAMsoles. All other trademarks not owned by JAMsoles that appear on this Website are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by JAMsoles.</p>
        
        <p>Nothing contained on the Website should be construed as granting, by implication, estoppel, or otherwise, any license or right to use the Website or any information displayed on the Website, through the use of framing or otherwise, except: (a) as expressly permitted by these Terms and Conditions of Business; or (b) with the prior written permission of JAMSOLES or the prior written permission from such third party that may own the trademark or copyright of information displayed on the Website.</p>
        
        <p>The intellectual property rights in all software and content made available to you on or through this Website remains the property of JAMsoles or its licensors and are protected by copyright laws and treaties around the world. All such rights are reserved by JAMsoles and its licensors. You may store, print, and display the content supplied solely for your own personal use. You are not permitted to publish, manipulate, distribute or otherwise reproduce, in any format, any of the content or copies of the content supplied to you or which appears on this Website, nor may you use any such content in connection with any business or commercial enterprise. You may link JAMsoles content to other sites, but only if you acknowledge the Website as the source of the material and do not disparage or discredit JAMsoles.</p>
        
        <p>You shall not modify, translate, reverse engineer, decompile, disassemble, or create derivative works based on any software or accompanying documentation supplied by JAMsoles or its licensors.</p>
        
        <h2>Terms Of Sale</h2>
        <p>By placing an order on the JAMsoles Website, you are offering to purchase a product on and subject to the following terms and conditions. All orders are subject to availability and confirmation of the order price. At any time, JAMsoles has the right to cancel the Order before it is dispatched, refunding any money due to the customer.
        </p>
        <p>JAMsoles will endeavor to process the Order and send the Goods within the time period stated at the point of purchase. Any dates quoted for delivery of the Goods are indicative only. Time for delivery will not be of the essence of the Contract, and JAMsoles will not be liable for any loss or expenses sustained by the Buyer arising from any delay in the delivery of the Goods, howsoever caused. JAMsoles will not be held responsible for any deliveries delayed due to postal delays or force majeure.</p>
        
        <p>In order to contract with JAMsoles, you must be at least 18 years of age and can form a legally binding contract under applicable law. If your order is accepted, we will inform you by e-mail when we dispatch the item.</p>
        
        <p>When placing an order, you undertake that all details you provide to us are true and accurate, that you are an authorized user of the credit, debit card, or PayPal account used to place your order, and that there are sufficient funds to cover the order cost. We reserve the right to obtain validation of your credit card, debit card, or PayPal details before accepting your Order. All prices advertised are subject to change.</p>
        
        <h2>Pricing And Payment</h2>
        <p>At JAMsoles, we try to ensure that all details, descriptions, and prices that appear on this Website are accurate. In the unlikely event of an error in the price of any goods that you have ordered, we will inform you of this as soon as possible and give you the option of reconfirming your order at the correct price or canceling it. If we are unable to contact you, we will treat the order as canceled, and any monies paid will be refunded to you.</p>
        
        <p><b>Taxes, Duties, and Extra Charges:</b> Prices advertised on the Website may not include shipping and handling charges or applicable country or region-specific sales, goods, or services taxes, which may be added to the price you pay. You are also responsible for any customs duties or any other similar fees associated with your Order.</p>
        
        <p>Upon receiving your order, we carry out a standard pre-authorization check on your payment card to ensure that there are sufficient funds to fulfill the transaction. Goods will not be dispatched until this pre-authorization check has been completed. Your card will be debited once the order has been accepted.</p>
        
        <p>We may use your personal information to conduct appropriate anti-fraud checks.</p>
        
        <p>Personal Information that you provide may be disclosed to a credit reference or fraud prevention agency, which may keep a record of that information.</p>
        
        <h2>Shipping</h2>
        <p>Products will generally be shipped to you within approximately 12 days of the date upon receiving the impression box from the customer and confirming the impression was taken as instructed.</p>
        
        <h2>No Medical Advice</h2>
        <p>You acknowledge and agree that JAMsoles does not provide any form of medical care, medical opinion, medical advice, diagnosis, or treatment, and that JAMsoles does not evaluate whether or not you should seek medical attention through the JAMsoles Site. The contents of the JAMsoles Website, such as articles, graphics, images, information from our third-party contributors, Product descriptions and instructions, and other material contained on the Website are for informational purposes only. Although such Content may be provided by individuals in the medical profession, the provision of such Content does not create a doctor-patient relationship, and it does not constitute a medical opinion, medical advice, or diagnosis or treatment of any particular condition. The Content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified healthcare provider with any questions you may have regarding a medical condition.</p>
        
        <h2>Returns And Refunds</h2>
        <p>If you are unsatisfied with your purchase, you may return it to us for a refund within 120 days. In order to return the product, you should contact our customer service (see under FAQ Section on the support page on-site) or contact <a href="mailto:customercare@jamsoles.com">customercare@jamsoles.com</a>.</p>
        
        <h2>Disclaimer Of Liability</h2>
        <p>The information contained on this Website is for general information purposes only. The information is provided by JAMsoles, and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the Website or the information, products, services, or related graphics contained on the Website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
        
        <p>In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Website.</p>
        
        <p>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful material that may infect your computer equipment, computer programs, data, or other proprietary material due to your use of this Website or to your downloading of any material posted on it, or on any website linked to it.</p>
        
        <p>Information on the JAMsoles website may contain technical inaccuracies or typographical errors. JAMsoles attempts to make its descriptions as accurate as possible but does not warrant that the content of the JAMSOLES website is accurate, complete, reliable, current, or error-free.</p>
        
        <h2>Indemnity</h2>
        <p>You agree fully to indemnify, defend and hold us, and our directors, employees, agents and suppliers, harmless immediately on demand, from and against all claims, liability, damages, losses, costs and expenses, including reasonable legal fees, arising out of any breach of the Conditions by you or any other liabilities arising out of your use of this Website, or the use by any other person accessing the Website using your shopping account and/or your Personal Information.</p>
        
        <h2>Variation</h2>
        <p>JAMsoles shall have the right in its absolute discretion at any time and without notice to amend, remove, or vary any of its services or any page of this Website.</p>
        
        <h2>Invalidity</h2>
        <p>If any part of the Terms and Conditions is unenforceable (including any provision in which we exclude our liability to you), the enforceability of any other part of the Terms and Conditions will not be affected with all other clauses remaining in full force and effect. So far as possible, where any clause/sub-clause or part of a clause/sub-clause can be severed to render the remaining part valid, the clause shall be interpreted accordingly. Alternatively, you agree that the clause shall be rectified and interpreted in such a way that closely resembles the original meaning of the clause/sub-clause as is permitted by law.</p>
        
        <h2>Complaints</h2>
        <p>We will try to do everything we can to resolve any disputes when they first arise. If you do have any complaints or comments, then please contact us at <a  href="mailto:customercare@jamsoles.com">customercare@JAMSOLES.com</a></p>
        
        <h2>Waiver</h2>
        <p>If you breach these conditions and we take no action, we will be entitled to exercise our rights in any other situation where you are in breach of our terms and conditions.</p>
        
        <h2>Entire Agreement</h2>
        <p>These Terms and Conditions constitute the entire agreement of the parties and supersede any and all preceding and contemporaneous agreements between you and JAMsoles. Any waiver of any provision of the Terms and Conditions will be effective only if in writing and signed by a Director of JAMsoles.</p>
        
        <p>We comply with US legal and tax regulations.</p>
      </div>
      </div>
    </div>
  </div>
  