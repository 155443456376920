<div class="header">
  <div class="top">
    <p style="letter-spacing: 2px;margin:0;">SIGN UP FOR 10% OFF YOUR FIRST PURCHASE</p>
  </div>
<header class="" style="padding-bottom:5px;border-bottom: 2px solid #000;" >
      <div class="container">
      <div class="header_menu_container">
        <div class="mobile_menu_container" >
          <a href="/">
            <img src="/assets/images/jamsole-logo.jpg" class="logo common_logo" alt="Jamsoles logo" title="Jamsoles logo" width="250px"/>
            <img src="/assets/images/Jam Soles footer-logo.png" width="250px" class="logo home_page_logo" alt="Jamsoles logo" title="Jamsoles logo"/>
          </a>
         
        </div>
        <div class="mnu">
          <a routerLink="#" class="mobile_menu_icon" routerLinkActive="active" (click)="show = !show" ><i class="fa fa-bars" aria-hidden="true"></i></a>
         <ul class="desktop_menu nav navbar-nav">
            <li>
              <a routerLink="/get-started" routerLinkActive="active" >SHOP NOW  </a>
            </li>
            <li> 
              <a style="cursor:pointer;" (click)="buy_popup = !buy_popup" routerLinkActive="active" >BUY AGAIN</a> 
            </li>
          <li>
            <a href="tel:866-461-3304" class="menu_phone">866-461-3304</a>
          </li>
          <li>
            <a href="https://www.instagram.com/yourjamsoles/" target="_blank" routerLinkActive="active" ><i class="fa fa-instagram" aria-hidden="true"></i></a>
          </li>
          <!-- <li>
            <a routerLink="#" routerLinkActive="active" ><i class="fa fa-facebook" aria-hidden="true"></i></a>
          </li>
              <li>
                <a routerLink="#" routerLinkActive="active" ><i class="fa fa-twitter" aria-hidden="true"></i></a>
            </li> -->
              <li>
                <a routerLink="#" routerLinkActive="active" (click)="show = !show" ><i class="fa fa-bars" aria-hidden="true"></i></a>
            </li>
         </ul>

    <div id="buy_again_myModal" class="buy_again_modal" [class.active_popup]="buy_popup">
     <div class="buy_again_modal-content">
    <span class="buy_again_close" (click)="buy_popup = !buy_popup">&times;</span>
    <h2>Buy Again</h2>
      <form [formGroup]="buy_again_form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>Email</label>
        <input type="email" required name="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" 
        formControlName="email"/>
        <p *ngIf="!order && no_order" class="invalid-feedback">No Orders Found on this Email</p>
        <p  *ngIf=" !no_order && order" style="color:#078507;font-weight:bold">Your order link send to your email.</p>
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required && !no_order || !order">Email is required</div>
      </div>
      </div>
      <div class="form-group">
        <input type="submit" class="submit_btn" value="Submit" name="sumbit" />
      </div>
    </form>
  </div>

</div>
        
        </div>
      </div>
      </div>
</header> 
</div>
<div class="menu_slider" [class.opened]="show">
  <div class="colse_slider">
    <a href="#" (click)="show = !show"><i class="fa fa-times" aria-hidden="true"></i></a>
  </div>
  <ul>
    <li><a routerLink="/" routerLinkActive="active" (click)="show = !show">Home</a></li>
    <li>
      <a routerLink="/get-started" routerLinkActive="active" (click)="show = !show">Shop NOw</a>
    </li>
    <li> 
      <a  data-toggle="modal" style="cursor:pointer;" (click)="show = !show" (click)="buy_popup = !buy_popup" data-target="#buy_again"  routerLinkActive="active" >Buy Again</a> 
    </li>
  <!-- <li>
    <a href="tel:866-461-3304" (click)="show = !show" style="color:#39a4b6;">866-461-3304</a>
  </li> -->
    <li><a routerLink="/faq" (click)="show = !show" routerLinkActive="active">Faq's</a></li>
    <li><a routerLink="/contact" (click)="show = !show" routerLinkActive="active">Contact</a></li>
  </ul>
  <div class="footer_links">
    <ul>
      <li><a href="tel:866-461-3304" class="network-icon call"><i class="fa fa-phone" aria-hidden="true"></i></a></li>
      <li><a href="https://www.instagram.com/yourjamsoles/" target="_blank" class="network-icon instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
      <!-- <li><a href="#" class="network-icon facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
      <li><a href="#" class="network-icon twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li> -->
    </ul>
  </div>
</div>


<!--<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">Navbar</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link"routerLink="/story" routerLinkActive="active">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/why-jamsole" routerLinkActive="active">Why JAMSOLES</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/features" routerLinkActive="active">Features</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/contact" routerLinkActive="active">Contact</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown link
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/story" routerLinkActive="active" >Story</a>
          <a class="dropdown-item" routerLink="/why-jamsole" routerLinkActive="active" >Why JAMSOLES</a>
          <a class="dropdown-item" routerLink="/features" routerLinkActive="active" >Features</a>
          <a class="dropdown-item" routerLink="/contact" routerLinkActive="active" >Contact</a>
        </div>
      </li>
    </ul>
  </div>
</nav>-->