import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ServicesService } from '../services.service';
import { Router } from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
data : any;
response : any;
error : any;
question: any;
faq: any;
url:any;
answer: any;
displayString: any;
  constructor(private service: ServicesService, private route: Router) { }

  ngOnInit(): void {
    

    this.service.getFaq()
    .subscribe(
      (response) => {                           //next() callback
       this.data=response;
      
        
      },
      (error) => {                              //error() callback
        console.error('Request failed with error')
       
      },
      () => {                                   //complete() callback
        console.error('Request completed')      //This is actually not needed 
        
      })

  }

}
