import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.css']
})

export class GetStartedComponent implements OnInit {

  constructor(private _formBuilder: FormBuilder,private route: Router) { }
  gender = new FormControl('');
  category = new FormControl('');
  step: number=1;
  error: boolean;
  msg:string;
  gender_chk: boolean;
  cat_chk:boolean;
  ngOnInit(): void {
    this.error=false;
    this.gender.setValue(localStorage.getItem('gender'));
    this.category.setValue(localStorage.getItem('category'));
    this.gender_chk=this.gender.value;
    this.cat_chk=this.category.value;
  }
  goNext(stepper: MatStepper){
    this.msg=this.gender.value;
    console.log(this.gender.value)
   if(!this.gender.value) this.error=true;
    else {

     this.error=false;
      localStorage.setItem('gender', this.gender.value )
      stepper.next();
      this.step=2;
    }
  }
  goNext1(stepper: MatStepper){
    this.msg=this.category.value;
   if(!this.category.value) this.error=true; 
    else {
     this.error=false;
      localStorage.setItem('category', this.category.value )
      stepper.next();
      this.route.navigate(['/product/'+localStorage.getItem('gender')?.toLowerCase()+'-'+this.category.value]);
    }
  }
  goBack(stepper: MatStepper){
    this.step=1;
   
  }
  onSubmit() {
    // TODO: Use EventEmitter with form value
   
  }

}
