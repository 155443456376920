import { newArray } from '@angular/compiler/src/util';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl} from '@angular/forms';
import { NgModule }             from '@angular/core';
import { ServicesService } from '../services.service';
import { AppRoutingModule } from '../app-routing.module';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css']
})

export class ProductViewComponent implements OnInit {
  gender:any;
  category:any;
  category_name:any;
  slug:any;
  title: string;
  point_1:any;
  point_2:any;
  point_3:any;
  price:number;
  sale_price:number;
  available_size:any;
  available_colors:any;
  gallery:[];
  bottom_gallery:[];
  description:any;
  image:any;
  bigImage:any;
  error: boolean;
  loading:any;
  msg:string;
  errorMessage:string;
  id:any;
  response: null;
  selected_size:any;
  selected_color:any;
  order_data:any;

  constructor(private service: ServicesService, private route: Router,private route1: ActivatedRoute,) { }
  
  size = new FormControl('');
  color = new FormControl('');
  
  changeImage(img:any){
    this.image= 'large-'+img;
  }
  ngOnInit(): void {
      this.slug =  this.route1.snapshot.paramMap.get('slug');
      this.size.setValue(localStorage.getItem('size'));
      this.color.setValue(localStorage.getItem('color'));
      this.selected_size=this.size.value;
      this.selected_color=this.color.value;
      console.log(this.selected_size)
      this.loading=true;
      this.service.getProduct(this.slug)
      .subscribe(
        (response) => {                           //next() callback
          //console.log('response received');
          this.id = response.id;
          localStorage.setItem('category', response.slug );
          localStorage.setItem('gender', response.gender );
          this.category_name = response.category; 
          this.point_1 = response.point_1;
          this.point_2 = response.point_2;
          this.point_3 = response.point_3; 
          this.price = response.price;
          this.sale_price = response.sale_price;
          this.description = response.description;
          this.available_size = response.available_size.split(',');
          this.available_colors = response.available_colors.split(',');
          this.image = response.image;
          this.bigImage = response.image;
          this.gallery = response.gallery.split(",");
          this.bottom_gallery = response.bottom_gallery.split(",");
          //console.log(this.image);
         this.loading=false;
     
        
        },
        (error) => {   
         //error() callback
         
          console.error('Request failed with error')
         
        },
        () => {       
                                //complete() callback
          console.error('Request completed')      //This is actually not needed 
          
          
        })
        
        if(this.response==null){
          //console.log("test");
          //this.route.navigate(['/home']);
       }
  }
  
  setImage(img:any){
    this.bigImage = img;
    //console.log(img);
  }
  getBackgroundColor(item:any){
    return item;     
    
  }
  getStarted(){
    
    if(this.size.value=='' && this.color.value=='') {
      this.error=true;
      this.errorMessage ="Please Select Size & Color!";
    }
    else if(this.size.value=='' ) {
      this.error=true;
      this.errorMessage ="Please Select Size!";
    } else if(this.color.value=='') {
      this.error=true;
      this.errorMessage ="Please Select Color!";
      
    }
    else {
     this.error=false;
     this.errorMessage ="";
     localStorage.setItem('size', this.size.value );
     localStorage.setItem('color', this.color.value );
     localStorage.setItem('id', this.id.value )
     this.route.navigate(['/checkout']);
     
    
    }
  }
 
}
