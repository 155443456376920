import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent} from './home/home.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { ProductViewComponent} from './product-view/product-view.component';
import { StoryComponent } from './story/story.component';
import { WhyJamsoleComponent } from './why-jamsole/why-jamsole.component';
import { FeaturesComponent } from './features/features.component';
import { ContactComponent } from './contact/contact.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentComponent } from './payment/payment.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { FaqComponent } from './faq/faq.component';
import { ProcessUserComponent } from './process-user/process-user.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ReturnsRefundsComponent } from './returns-refunds/returns-refunds.component';
import { SubmissionComponent } from './submission/submission.component';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { PlaceJamsolesComponent } from './place-jamsoles/place-jamsoles.component';
import { FoamBoxComponent } from './foam-box/foam-box.component';
import { ExplainerVideoComponent } from './explainer-video/explainer-video.component';
import { InsuranceComponent } from './insurance/insurance.component';

const routes: Routes = [ 
                        { path: '', component: HomeComponent },
                        { path: 'get-started', component: GetStartedComponent },
                        { path: 'product/:slug', component: ProductViewComponent },
                        { path: 'user/:order_id', component: ProcessUserComponent },
                        { path: 'story', component: StoryComponent },
                        { path: 'why-jamsole', component: WhyJamsoleComponent },
                        { path: 'features', component: FeaturesComponent },
                        { path: 'contact', component: ContactComponent },
                        { path: 'checkout', component: CheckoutComponent },
                        { path: 'payment', component: PaymentComponent },
                        { path: 'insurance', component: InsuranceComponent },
                        { path: 'thankyou', component: ThankyouComponent },
                        { path: 'faq', component: FaqComponent},
                        { path: 'privacy-policy', component: PrivacyPolicyComponent},
                        { path: 'terms-conditions', component: TermsConditionsComponent},
                        { path: 'returns-refunds', component: ReturnsRefundsComponent},
                        { path: 'submission', component: SubmissionComponent},
                        { path: 'how-to-place-jamsoles-into-shoes', component: PlaceJamsolesComponent},
                        { path: 'step-into-the-foam-box', component: FoamBoxComponent},
                        { path: 'about-jamsoles-explainer-video', component: ExplainerVideoComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
