<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="video_box">
       <h1 style="text-align:center;">How to cut the device</h1>
      <video style="width:100%" onloadedmetadata="this.muted=true" playsinline autoplay controls loop muted  >
        <source  src="/assets/images/foam-box.mp4" type="video/mp4"  />
     </video>
    </div>
    </div>
  </div>
</div>