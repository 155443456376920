<!-- <div class="banner">
  <div class="container">
    <div class="row ortho-div">
      <div class="col-sm-5 left">
          <div class="soc">
               <a href="#" target="_blank" rel="noopener">
                   <img loading="lazy" src="/assets/images/insta.png" width="auto" height="auto" alt="Instagram logo" title="Instagram logo"></a>
             <a href="#" target="_blank" rel="noopener">
                 <img loading="lazy" src="/assets/images/Facbook.png" width="auto" height="auto" alt="Facebook logo" title="Facebook logo"></a>
               <a href="#" target="_blank" rel="noopener">
                   <img loading="lazy" src="/assets/images/twitter.png" width="auto" height="auto" alt="Twitter logo" title="Twitter logo"></a>
          </div></div>
      <div class="col-sm-7 ortho-text">
          <h1 class="ortho-text-h1">Orthotics for Your Feet And Your Feet Alone</h1>
          <a _ngcontent-nsw-c16="" routerlink="/get-started" routerlinkactive="active" class="btn1 btn-home" ng-reflect-router-link="/get-started" ng-reflect-router-link-active="active" href="/get-started">Order JAMsoles</a></div>
    </div>
</div>
</div> -->
<div class="jamsoles_banner">

  <div class="banner_video_section">
    <video oncanplay="this.play()" preload="false" id="banner_vd" #video playsinline onloadedmetadata="this.muted=true" style="vertical-align:bottom;" autoplay loop muted  class="desktop_video">
      <source  src="/assets/images/banner_video.mp4" type="video/mp4"  />
      <source  src="/assets/images/banner_video.ogv" type="video/ogv"  />
      <source  src="/assets/images/banner_video.webm" type="video/webm"  />
   </video>
    <video onloadedmetadata="this.muted=true" playsinline preload="false"  id="banner_vd" oncanplay="this.play()" #video1 autoplay loop muted  class="mobile_video" >
      <source src="/assets/images/mobile_banner_video.mp4" type="video/mp4" />
      <source src="/assets/images/mobile_banner_video.ogv" type="video/ogv" />
      <source src="/assets/images/mobile_banner_video.webm" type="video/webm" />
   </video>
   <img *ngIf="!sound" src="/assets/images/mute.png" (click)="videoSound('banner_vd')" class="mute_video"/>
   <img *ngIf="sound" src="/assets/images/unmute.png" (click)="videoSound('banner_vd')" class="mute_video"/>
</div>

</div>


<div class="">
  <div class="comp_section">
     <div class="half_section girl_image furleftpdg" style="padding:0;background-image:url('/assets/images/home_ban2.png');">
          <!-- <img src="/assets/images/home_ban2.png" style="margin:0;" width="100%" height="auto" alt="Image of a girl model from backside, walking with Jamsoles" title="Orthotics for your feet and your feet alone"/> -->
         </div>
      <div class="half_section rgtpdg"  style="padding:0;background: #59bacb;">
        <div class="blocks_container">
       <div class="blocks btmpdg1">
           <img src="/assets/images/ft1.png" width="auto" height="auto" alt="Grphical Image showing foot steps" title="CChoose your shoe"/>
           <h3>Choose Your Shoe type</h3>
           <p style="font-size:20px"><b>Orthotics that match your preferred shoe type, shape, and size.</b></p>
         </div>
          <div class="blocks btmpdg2">
               <img src="/assets/images/ft2.png" width="auto" height="auto" alt="Graphical image right leg" title="Convenience and precision"/>
           <h3>Convenience & Precision</h3>
           <p  style="font-size:20px"><b>Imprint your feet with our kit for the fastest and most accurate orthotics modeling.</b></p>
         </div>
         </div>
         <div class="blocks_container">
          <div class="blocks btmpdg3">
               <img src="/assets/images/ft3.png" width="auto" height="auto" alt="Graphical image showing right shoe" title="Stay Free"/>
           <h3>Stay Free</h3>
           <p  style="font-size:20px"><b>Greater comfort allows you to move more and stay active.</b></p>
         </div>
          <div class="blocks btmpdg4">
               <img src="/assets/images/ft4.png" width="auto" height="auto" alt="Graphical image showing foot prints" title="Pave Your Path"/>
           <h3>Pave Your Path</h3>
           <p  style="font-size:20px"><b>Explore a new world of comfort with your truly custom orthotics.</b></p>
         </div>
         </div>
        </div>
      </div>
</div>

<div class="container">
  <section class="buy_now">
    <h2 class="text-center">Buy Now</h2>
    <div class="circle-container"> 
      <div (click)="savePaymentType('self_pay')" routerLinkActive="active"  class="circle self-cir">
          <h3>Self Pay</h3>
      </div>
      <div (click)="savePaymentType('insurance')" routerLinkActive="active"  class="circle ppo-cir">
        <h3 class="PPO">Use My PPO Insurance</h3><span>or</span><h3>HSA</h3><span  style="text-align: center;">Health Savings <br/>Account</span>
      </div>
  </div>
  </section>
  </div>

<div class="container">
  <div class="row">
    <div class="col-md-12">
       <div class="home_section3">
        <h2>Custom Medical - Grade Foot Orthotics</h2>
        <h3>Direct To your door in 3 easy steps. End chronic foot pain now or simply<br/> enhance your gait for optimum performance</h3>
       </div>
    </div>
  </div>
</div>


<div class="mid home_section4">
<div class="container">
    <div class="row">
      <div class="col-sm-4 bpad">
          <img src="/assets/images/prductstep1.png" width="auto" height="auto" alt="Graphical image showing foot with different angles" title="Customized questions"/>
           <h3>Customization Questions</h3>
           <p> Click on <b>Order Now</b> to answer a few quick questions to get you
            on your way to medical-grade custom orthotics direct to your door utilizing our proprietary foam box, which will allow you to
            take a perfect impression of your foot</p>

         </div>
      <div class="col-sm-4 bpad">
          <img src="/assets/images/prductstep2.png" width="auto" height="auto" alt="Grpahical image showing foot imprint kit" title="Imprint Your Foot at Home with Our JAMsoles Kit"/>
           <h3>Imprint Your Foot at Home with Our JAMsoles Kit</h3>
           <p>Once you receive our proprietary box, simply step into it.
            It's Easy and Fun (see video), and send it back free
            to our expert labs. </p>

           </div>
      <div class="col-sm-4 bpad">
          <img src="/assets/images/prductstep3.png" width="auto" height="auto" alt="Graphical image showing jamsoles in a box" title="Receive Your JAMsoles and Get Moving"/>
          <h3>Receive Your JAMsoles and Get Moving</h3>
          <p>Receive Jamsoles at your door.
            You're ready to experience pain-free gait and
           optimum performance
           in every step.</p>
          </div>
    </div>
    <!-- <p class="text-center"><a routerLink="/get-started" routerLinkActive="active" class="btn1 btn-home">Order JAMsoles</a>
    </p> -->
</div>
</div>

<div class="home_section5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>JUST CLICK HERE TO LEARN HOW IT WORKS!!!</h2>
        <div class="row">
          <div class="col-md-8 col-md-offset-2 learn_logo">
            <video width="100%" muted loop="true" poster="/assets/images/video_thumb.png" class="reverse_lvideo" onloadedmetadata="this.muted=true" controls="controls" style="position: relative;">
              <source src="/assets/images/learn_video.mp4" type="video/mp4" />
          </video>
          <img src="/assets/images/reverse_logo.png" class="reverse_vlogo" width="50px" alt="Jamsoles logo" title="Jamsoles logo"/>
          </div>
        </div>
        <div class="order_btn_new">Order Now</div>
        <div class="container">
          <section class="buy_now2">
            <div class="circle-container"> 
              <div (click)="savePaymentType('self_pay')" routerLinkActive="active"  class="circle self-cir">
                  <h3>Self Pay</h3>
              </div>
              <div (click)="savePaymentType('insurance')" routerLinkActive="active"  class="circle ppo-cir">
                <h3 class="PPO">Use My PPO Insurance</h3><span>or</span><h3>HSA</h3><span  style="text-align: center;">Health Savings <br/>Account</span>
              </div>
          </div>
          </section>
          </div>
      </div>
    </div>
  </div>
</div>

<div class="home_section6">
      <div class="col-md-12 col-sm-12 box">
        <div class="container">
          <div class="col-md-4 col-sm-4">
            <div class="single_box">
            <a href="/step-into-the-foam-box">
            <img src="/assets/images/home_cut.png" width="100%" height="auto" alt="image"/>
          </a>
            <h4 style="font-size: 25px;"><b>How to cut the device</b></h4>
          </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <div class="single_box">
              <a href="/how-to-place-jamsoles-into-shoes">
            <img src="/assets/images/home_cast.png" width="100%" height="auto" alt="image"/>
            </a>
            <h4 style="font-size: 25px;"><b>How to cast</b></h4>
          </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <div class="single_box">
              <a href="/about-jamsoles-explainer-video">
            <img src="/assets/images/home_product.png" width="100%" height="auto" alt="image"/>
            </a>
            <h4 style="font-size: 25px;"><b>About the product</b></h4>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
</div>


<div class="home_section7">
    <div class="sec7_item">
       <div style="max-width: 500px;width:100%;padding:15px">
        <h1 style="font-size: 50px;letter-spacing: 2px;">Every Contour Your Own</h1>
        <p style="font-size: 25px;"><b>We model every inch of your orthotics off your custom imprint. From heel to toe, every contour of the insert matches your foot's unique shape.</b></p>
      </div>
    </div>
    <div class="sec7_item">
     <img src="assets/images/sec7_img.png" alt="image" />
    </div>
    <div class="clearfix"></div>
</div>
<!-- <div class="container">
    <div class="row midleft">
      <div class="col-sm-6">
          <img src="/assets/images/jam-slip.jpg" width="auto" height="auto" alt="Image showing custom imprint of orthotics" title="Every contour your own"/>
         </div>
      <div class="col-sm-6 leftpad">
           <h3>EVERY CONTOUR YOUR OWN</h3>
           <p>We model every inch of your orthotics off your custom imprint. From heel to toe, every contour of the insert matches your foot's unique shape.</p>
           <img src="/assets/images/jamsole-logo.jpg" width="auto" height="auto" alt="Jamsoles logo" title="Jamsoles logo"/>
          
           </div>
           
  </div>
</div> -->
     
<!-- <div class="rgtside">    
  <div class="container">
    <div class="row midleft">
      <div class="col-sm-6 rgttpad exp_det">
           <h3>CRAFTED BY EXPERTS</h3>
           <p>Our team of skilled experts understands the unique anatomy of the foot, crafting orthotics according to years of podiatry training and experience.</p>
          <div class="btn_order"><a _ngcontent-ump-c16="" routerlink="/get-started" routerlinkactive="active" class="btn1 btn-home" ng-reflect-router-link="/get-started" ng-reflect-router-link-active="active" href="/get-started" data-uw-styling-context="true">Order JAMsoles</a></div>
          
           </div>
           <div class="col-sm-6">
          <img src="/assets/images/jamsole.jpg" width="auto" height="auto" alt="Image of Dr Kamran Jamshidinia" title="Crafted by experts"/>
         </div>
           
     </div>
  </div>
</div>  -->

<div class="home_section8">
  <img src="assets/images/sec8_img.png" alt="image" />
  <div>
    <p style="font-size: 25px;max-width: 900px;margin: 50px auto;width: 100%;text-align: center;"><b>Our team of skilled experts understand the unique anatomy of the foot, crafting orthotics according to years of podiatry training and experience.</b></p>
    <div class="order_btn_new">Order Jamsoles</div>
    <div class="container">
      <section class="buy_now3">
        <div class="circle-container"> 
          <div (click)="savePaymentType('self_pay')" routerLinkActive="active"  class="circle self-cir">
              <h3>Self Pay</h3>
          </div>
          <div (click)="savePaymentType('insurance')" routerLinkActive="active"  class="circle ppo-cir">
            <h3 class="PPO">Use My PPO Insurance</h3><span>or</span><h3>HSA</h3><span  style="text-align: center;">Health Savings <br/>Account</span>
          </div>
      </div>
      </section>
      </div>
  </div>
</div>
     
<div class="full">
  <div class="fullimg">
            <!-- <img src="/assets/images/jam-run.jpg" width="auto" height="auto" alt="Image showing an athelete about to run" title="Built for performance"/> -->
            <img src="/assets/images/run_new.png" width="auto" height="auto" alt="Image showing an athelete about to run" title="Built for performance"/>
         </div>
     <div class="bluesection">
     <h2>BUILT FOR PERFORMANCE</h2>
   <div class="row">
      <div class="col-sm-4 nopad">
           <h3 style="font-size: 30px;">Comfort from Quality</h3>
           <p style="font-size: 18px;"><b>We use only the most advanced
            materials in a state-of-the-art facility to produce long-lasting, comfort-centric orthotics.</b></p>

         </div>
      <div class="col-sm-4 nopad">
           <h3 style="font-size: 30px;">Along for the Ride</h3>
           <p style="font-size: 18px;"><b>Orthotics that match your preferred shoe type, shape, and size.</b></p>

           </div>
      <div class="col-sm-4 nopad">
          <h3 style="font-size: 30px;">CHOOSE YOUR SHOE</h3>
          <p style="font-size: 18px;"><b>Orthotics that match your preferred shoe type, shape, and size.</b></p>

      </div>
    </div>
</div>
</div>

<div class="fursction vrtical">
  <div class="container">
            <h2>WALK. RUN. PLAY. LIVE.</h2>
            <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="row">
       <div class="col-sm-6 vid">
         <img class="pic_video" (click)="loadVideo('jamsole-vid-1.mp4')" src="/assets/images/foot1.jpg" width="auto" height="auto" alt="Thumbnail for video1" title="Thumbnail for video 1" />
                         
         </div>
          <div class="col-sm-6 vid1">
           <img class="pic_video" (click)="loadVideo('jamsole-vid-2.mp4')" src="/assets/images/foot2.jpg" width="auto" height="auto" alt="Thumbnail for video2" title="Thumbnail for video2" data-video="jamsole-vid-2.mp4"/>
            
         </div>
          <div class="col-sm-6 vid2">
            <img class="pic_video" (click)="loadVideo('jamsole-vid-3.mp4')" src="/assets/images/santa.jpg" alt="Thumbnail for video4" title="Thumbnail for video4" data-video="jamsole-vid-3.mp4"/>
          
         </div>
        <!--  <div class="col-sm-6 vid3">
               <img src="/assets/images/foot5.jpg" width="auto" height="auto" alt="Thumbnail for video5" title="Thumbnail for video5"/>
          
         </div>-->
         </div>
      </div>
      <div class="col-sm-6 col-md-8 vid4">
         <!--  <img src="/assets/images/foot3.jpg" width="auto" height="auto" alt="Thumbnail for video3" title="Thumbnail for video3"/>-->
          <video id="movie" width="100%" height="auto" src="{{video_url}}" controls >
          </video>
      </div>
      </div>
  </div>
</div>