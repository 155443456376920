import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ServicesService } from '../services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  submitted = false;
  response : any;
  res : any;
  subscribe : any;
  servererror:any;
  buy_again_form: FormGroup;
  public isHome: false;
  public show: boolean = false;
  public buy_popup: boolean = false;
  order:boolean;
 no_order:boolean;
 constructor(private _formBuilder: FormBuilder,private service: ServicesService,private route: Router) {}

  ngOnInit(): void {
    this.buy_again_form = this._formBuilder.group({
       email: [null, [Validators.required, Validators.email]],
     });
  }
  get f() { return this.buy_again_form.controls; }

  onSubmit() {
    this.submitted = true; 
        if (this.buy_again_form.invalid){
          return; 
        }     
  this.service.previousOrderChecking(this.buy_again_form.value).subscribe((res:any) => {
    if(res.status=='success') {
    this.buy_again_form.reset();
    this.order=true;
    this.no_order=false;
    this.submitted = false;
    //this.route.navigate(['/submission']);
    } else if(res.status='failed'){
      this.no_order=true;
      this.order=false;
      this.submitted = false;
    } 
    else {
      this.servererror =res.message;
      return;
    } 
});  
}
}
