<div class="jam_body">
<div class="innerbanner">
    <div class="container">
      <h1>Frequently Asked Questions</h1>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
      <div class="faq-div"  *ngFor = "let item of data">
            <div class="question"><p>{{item.question}}</p></div>
            <div class="answer" [innerHTML]="item.answer" ><p>{{item.answer}}</p>
            </div>
          </div>
      </div>
    </div>
  </div>
  </div>
  