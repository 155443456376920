import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common'; 

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  payment_type : string;
  constructor(private router: Router ,private route: Router) {}  
  video_url:string = '/assets/images/jamsole-vid-1.mp4';
  sound:boolean;
  vd:any;
  pauseOrPlay(video : any){
    if (video.paused) video.play();
    else video.pause();
}
  pauseOrPlayMobile(video : any){
    if (video.paused) video.play();
    else video.pause();
}
  videoSound(video : any){
    this.sound=!this.sound;
    this.vd = document.getElementById(video);
    this.vd.muted=this.sound;
    console.log(this.vd)
}
  ngOnInit(): void {
    this.sound=true;
  }
  loadVideo(url:string){
  this.video_url = '/assets/images/'+url;
  }

  savePaymentType(type:string){
    localStorage.setItem('payment_type', type );
    this.route.navigate(['/get-started']);
  }
}
