<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="video_box">
       <h1 style="text-align:center;">About the product</h1>
      <video style="width:100%" playsinline onloadedmetadata="this.muted=true" autoplay controls loop muted  >
        <source  src="/assets/images/banner_video.mp4" type="video/mp4"  />
        <source  src="/assets/images/banner_video.ogv" type="video/ogv"  />
        <source  src="/assets/images/banner_video.webm" type="video/webm"  />
     </video>
    </div>
    </div>
  </div>
</div>