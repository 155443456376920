<div class="jam_body">
<div class="innerbanner">
  <div class="container">
    <h1>Contact Jamsoles</h1>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-8  col-md-offset-2">
      <div class="contact_box">
        <div class="row">
          <div class="col-md-6">
            <h3>Questions and Comments</h3>
            <form action="" class="" [formGroup]="contactform" (ngSubmit)="onSubmit()" method="" name="">
              <div class="mb-3">
                <input type="text" class="form-control" id="name" placeholder="Name*" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" 
              formControlName="name">
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
            <div class="mb-3">

               
              <input type="text" class="form-control" id="email" placeholder="Email*" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
              </div>
              </div>
              <div class="mb-3">
                <input type="text" class="form-control" id="phone" placeholder="Phone*" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
              formControlName="phone">
              <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                <div *ngIf="f.phone.errors.phone">Phone Number must be valid</div>
            </div>
              </div>
              <div class="mb-3">
                <textarea type="text" class="form-control" id="question" placeholder="Your Question*"  [ngClass]="{ 'is-invalid': submitted && f.question.errors }" formControlName="question"></textarea>
                <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
                  <div *ngIf="f.question.errors.required">Question is required</div>
              </div>
              </div>
              <button class="btn1 btn-lg" type="submit">Send</button>
              <div class="server_msg" *ngIf = "servererror!=''" >{{servererror}}</div>
            </form>
          </div>
          <div class="col-md-6 address-box">
          <div class="address_div">
            <p>2080 Century Park East</p>
            <p>Suite 1508</p>
            <p>Los Angeles CA 90067</p>
          </div>  
          <hr>
          <div class="timing_div">
            <h4>Business Hours</h4>
            <p>Monday - Friday: 9am - 10pm (EST)</p>
            <p>Saturday and Sunday: 10am - 6pm (EST)</p>


          </div> 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

