import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ServicesService } from '../services.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
 submitted = false;
  size:any;
  color:any;
  category:any;
  gender:any;
  title: string;
  price:number;
  sale_price:number;
  available_size:any;
  available_colors:any;
  description:any;
  image:any;
  zip:any;
  company:any;
  state:any;
  qty:number=1;
  qty_text:string="1 Pair";
  order_data:any;
  payment_error:any;
  checked:boolean;


  shippingform: FormGroup;
  constructor(private _formBuilder: FormBuilder,private service: ServicesService,private route: Router) { }

  ngOnInit(): void {
    
    this.size = localStorage.getItem('size');
    this.color = localStorage.getItem('color');
    this.category = localStorage.getItem('category');
    this.order_data = JSON.parse(localStorage.getItem('order-data') || '{}');
    this.shippingform = this._formBuilder.group({
      id: [''],
      category: this.category,
      size: this.size,
      color:this.color,
      price:0,
      sale_price:0,
      quantity:1,
      email: [this.order_data.email, [Validators.required, Validators.email]],
      first_name: [this.order_data.first_name, Validators.required],
      last_name: [this.order_data.last_name, Validators.required],
      phone_number: [this.order_data.phone_number, Validators.required],
      address: [this.order_data.address, Validators.required],
        street: [this.order_data.street],
        city: [this.order_data.city, Validators.required],
        country: [this.order_data.country, Validators.required],
        state: [this.order_data.state, Validators.required],
        zip: [this.order_data.zip, Validators.required],
        sh_first_name: [this.order_data.sh_first_name, Validators.required],
        sh_last_name: [this.order_data.sh_last_name, Validators.required],
        sh_street: [this.order_data.street],
        sh_address: [this.order_data.sh_address, Validators.required],
        sh_city: [this.order_data.sh_city, Validators.required],
        sh_country: [this.order_data.sh_country, Validators.required],
        sh_state: [this.order_data.sh_state, Validators.required],
        sh_zip: [this.order_data.sh_zip, Validators.required]
    });

    this.service.getProductByCatSlug(this.category)
      .subscribe(
        (response) => {                           //next() callback
          console.log('response received');
          this.shippingform.controls["id"].setValue(response.id);
          this.shippingform.controls["price"].setValue(response.price);
          this.shippingform.controls["sale_price"].setValue(response.sale_price);
          this.category = response.category; 
          this.price = response.price;
          this.sale_price = response.sale_price;
          this.description = response.description;
          this.image = response.image;
        },
        (error) => {                              //error() callback
          console.error('Request failed with error')
         
        },
        () => {                                   //complete() callback
          console.error('Request completed')      //This is actually not needed 
        })
        
  }
  getBackgroundColor(color:any){
    return color;     
    
  }
  get f() { return this.shippingform.controls; }
 setQty(q:number){
   this.qty += q;
  
   if(this.qty<1) this.qty=1;
   this.qty_text=this.qty + (this.qty==1?' Pair':' Pairs');
   this.shippingform.controls["quantity"].setValue(this.qty);
 }

 onCheckboxChange(e:any) {
  if (e.target.checked) {
    this.checked=true;
    this.shippingform.patchValue({sh_first_name: this.shippingform.value.first_name,
                                  sh_last_name: this.shippingform.value.last_name,
                                  sh_address: this.shippingform.value.address,
                                  sh_city: this.shippingform.value.city,
                                  sh_street: this.shippingform.value.street,
                                  sh_state: this.shippingform.value.state,
                                  sh_country: this.shippingform.value.country,
                                  sh_zip: this.shippingform.value.zip
                                  });
    
  } else {
    this.checked=false;
    this.shippingform.patchValue({sh_first_name:'',
      sh_last_name: '',
      sh_address: '',
      sh_city: '',
      sh_state: '',
      sh_country: '',
      sh_zip: ''
      });
    
  }
  console.log(this.checked)
}
   
  onSubmit() {

    this.submitted = true;

        // stop here if form is invalid
        if (this.shippingform.invalid) {
            return;
        }
    localStorage.setItem('order-data', JSON.stringify(this.shippingform.value));
   this.route.navigate(['/payment']);

  }
  
}
