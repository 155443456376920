import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ServicesService } from '../services.service';
import { AppRoutingModule } from '../app-routing.module';

import { Router } from '@angular/router';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  submitted = false;
  response : any;
  res : any;
  subscribe : any;
  servererror:any;
  contactform: FormGroup;
  constructor(private _formBuilder: FormBuilder,private service: ServicesService,private route: Router) { }


  ngOnInit(): void {

    this.contactform = this._formBuilder.group({

     name: ['', Validators.required],
      phone: ['', Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      email: ['', Validators.required, Validators.email],
        question: ['', Validators.required]

    });
  }
  get f() { return this.contactform.controls; }
  onSubmit() {
    this.submitted = true; 
        if (this.contactform.invalid){
          return; 
        }    

 
      
  this.service.contactform(this.contactform.value).subscribe((res:any) => {
    if(res.status=='success') {
    this.contactform.reset();
    this.route.navigate(['/submission']);
    }
    else {
      this.servererror =res.message;
      return;
    } 
});  
  
}
}

