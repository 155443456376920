import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ServicesService } from '../services.service';
import { Router,ActivatedRoute } from '@angular/router';
import { TK_CONFIG, Config, AcceptJSService, CreditCard } from '@openutility/acceptjs-angular-wrapper';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  [x: string]: any;
  submitted = false;
  address:any;
  b_address:any;
  btn_text:any='  Make Payment ';
  payment_status:any;
  btn_payment_disable=false;
  category:any;
  order_data:any;
  payment_error:any;
  error: any;
  paymentform: FormGroup;

  payment_type = localStorage.getItem('payment_type');


  constructor(private _formBuilder: FormBuilder,private service: ServicesService,private router: Router,private route: Router,private _acceptJSSrv: AcceptJSService) { }
  size = new FormControl('');
  
  ngOnInit(): void {
    this.order_data = JSON.parse(localStorage.getItem('order-data') || '{}');
 this.address= '<p>'+this.order_data.first_name +' '+this.order_data.last_name+'<br/>'+this.order_data.address +', '+ this.order_data.street +', '+ this.order_data.city +', '+ this.order_data.state +'<br/>'+ this.order_data.zip +', '+ this.order_data.country +'<br/>'+'Email: '+ this.order_data.email+ '<br/>'+ 'Phone: '+this.order_data.phone_number+'</p> ';

 this.sh_address= '<p>'+this.order_data.sh_first_name +' '+this.order_data.sh_last_name+'<br/>'+ this.order_data.sh_address +', '+ this.order_data.sh_street +', '+ this.order_data.sh_city +', '+ this.order_data.sh_state +'<br/>'+ this.order_data.sh_zip +', '+ this.order_data.sh_country +'</p> ';
 this.paymentform = this._formBuilder.group({
  payment_status: [''],
  card_number: ['', Validators.required],
  exp_month: ['', Validators.required],
  exp_year: ['', Validators.required],
  card_code: ['', Validators.required],
});
  }
  get f() { return this.paymentform.controls; }
  async onSubmit(): Promise<void> {
    this.btn_text =' Submitting ';
    var cardNumber = (<HTMLInputElement>document.getElementById('card_number')).value;
    var expMonth = (<HTMLInputElement>document.getElementById('exp_month')).value;
    var expYear = (<HTMLInputElement>document.getElementById('exp_year')).value;
    var cardCode = (<HTMLInputElement>document.getElementById('card_code')).value;
    let cc={
      cardNumber:cardNumber,
      month:expMonth,
      year:expYear,
      cardCode:cardCode
    }

    try {
      this.submitted = true;
      if (this.paymentform.invalid) { 
        this.btn_text ='  Make Payment ';
        return;
    } else {
      this.payment_error ='';
    this.order_data.payment_status = this.paymentform.controls['payment_status'].value;
    //console.log(this.paymentform);
      const nonce = await this._acceptJSSrv.generatePaymentNonce(cc);
      console.log(nonce);
      this.order_data.nonce=nonce;
      this.service.createorder(this.order_data)
       .subscribe((response:any) => {
        this.order_data.order_id=response.order_id;
        console.log(response);
        localStorage.setItem('order-data', JSON.stringify(this.order_data));

         if(response.status=='success'){
            this.paymentform.reset();
            this.route.navigate(['/thankyou']);
         }
         else {
          this.payment_error = response.payment_response.description;
         }
         this.btn_text ='  Make Payment ';
      });  
    }
      // submit nonce to your server with payment amount
    } catch (ex:any) {
      console.error(ex);
      this.payment_error = ex.messages.message[0].text;
    }
  } 
  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}