<footer>
    <div class="container">
      <div class="row vrtical ftr">
             <!-- <div class="col-sm-4 ftr1">
             <p><span class="ftricon"><img src="/assets/images/plus.png"/></span> Lorem Ipsum Dolor</p>
             <p><span class="ftricon"><img src="/assets/images/question.png"/></span> Any question</p>
             <p><span class="ftricon"><img src="/assets/images/search.png"/></span> find our office</p>
        </div> -->
        <div class="col-sm-12 ftr2">
             <img src="/assets/images/Jam Soles footer-logo.png" width="auto" height="auto" alt="Jamsoles logo" title="Jamsoles logo"/>
        </div>
        <!--<div class="col-sm-4 ftr3">
             <p>Lorem Ipsum Dolor</p>
             <p>Lorem Ipsum Dolor</p>
             <p>Lorem Ipsum Dolor</p>
        </div>-->
      </div>
      <p class="copyright">© 2022  All Rights Reserved.&nbsp; &nbsp;<a class="privacy-link" routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a> | <a class="privacy-link" routerLink="/terms-conditions" routerLinkActive="active"> Terms and Conditions </a> | <a a class="privacy-link" routerLink="/returns-refunds" routerLinkActive="active">Returns and Refunds</a></p>
  </div>
</footer>
<script type="text/javascript" src="//cdn.callrail.com/companies/337767426/248ea3af0c7638e09f36/12/swap.js"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>